<template>
  <a-modal v-model="visible" title="报表导出" width="40%" @cancel="_close" cancelText="取消" okText="导出"
    :confirm-loading="confirmLoading" @ok="handleOk" centered destroyOnClose>

    <a-form-model :rules="rules" ref="modelForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="表单类型" prop="type">
        <a-select v-model="form.type" placeholder="请选择需要导出的表单">
          <a-select-option :value="1">
            物品统计报表
          </a-select-option>
          <a-select-option :value="2">
            物品分类统计报表
          </a-select-option>
          <a-select-option :value="3">
            部门领用物品分类报表
          </a-select-option>
          <a-select-option :value="5">
            部门领用细报表
          </a-select-option>
          <a-select-option :value="4">
            采购物品明细报表
          </a-select-option>
          <a-select-option :value="6">
            借用物品明细报表
          </a-select-option>
          <a-select-option :value="7">
            归还物品明细报表
          </a-select-option>
          <a-select-option :value="8">
            报废物品明细报表
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="日期区间" prop="startDate">
        <a-range-picker :placeholder="['开始日期', '截止日期']" @change="setDateRange" />
      </a-form-model-item>
      <a-form-model-item label="选择物品" prop="goodsIds" v-if="form.type == 1 || form.type >= 4">
        <selectgoods multiple v-model="form.goodsIds" :names="names" @confirm="confirmGoods"></selectgoods>
      </a-form-model-item>
      <a-form-model-item v-if="form.type == 3 || form.type == 5" label="选择部门" prop="depIds">
        <select-depart multiple :organId="apiUtil.getOrganId()" :value="form.depIds"
          @input="e => (form.depIds = e)"></select-depart>
      </a-form-model-item>
      <a-form-model-item label="物品分类" prop="categoryIds">
        <a-select mode="multiple" v-model="form.categoryIds" placeholder="请选择物品分类">
          <a-select-option v-for="item in categoryList" :key="item.categoryId" :value="item.categoryId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import moment from 'moment';
import ExporttoExcel from "@/common/ExportExcel.js";
import selectgoods from './selectgoods.vue';
import selectDepart from '@/components/common/select-depart.vue';
export default {
  components: { selectgoods, selectDepart },
  data() {
    return {
      visible: false,
      loading: false,
      confirmLoading: false,
      names: '',
      data: {
        title: '',
        content: ''
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        date: '',
        startDate: '',
        endDate: '',
        type: 1,
        depIds: '',
        categoryIds: [],
        goodsIds: []
      },
      rules: {
        type: [
          { required: true, message: '请选择需要导出的表', trigger: 'blur' },
        ], startDate: [
          { required: true, message: '请选择导出的日期区间' },
        ],
      },
    };
  },
  methods: {
    ...apiUtil,
    moment,
    setDateRange(data, str) {
      this.form.startDate = str[0];
      this.form.endDate = str[1];
    },
    confirmGoods(e) {
      let goodsIds = [];
      goodsIds = e.map(item => item.goodsId);

      this.form.goodsIds = goodsIds;
      // console.log(goodsIds);
    },
    show(row) {
      this.visible = true;
    },
    _close() {
      this.visible = false;
      this.$refs.modelForm.resetFields();
    },
    handleOk() {
      this.$refs.modelForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          let url = '/platform/goods/export';
          this.http
            .get(url, this.form)
            .then(ret => {
              if (ret.data.length > 0) {
                this.ExportExcel(ret.data);
              } else {
                utils.info("暂无数据！！！");
              }
              this.confirmLoading = false;
              // this.$refs.modelForm.resetFields();
            }).catch((error) => {
              this.confirmLoading = false;
            });

        } else {
          // this.visible = false;
          this.confirmLoading = false;
        }
      });
    },
    // 导出表格
    ExportExcel(data) {
      let filterData = [];
      let name = '';
      //物品统计报表
      if (this.form.type == 1) {
        name = `${this.form.startDate} - ${this.form.endDate}办公物资报表`
        let totalRow = { lastMonthStock: 0, lastMonthMoney: 0, buyNumber: 0, buyMoney: 0, backNumber: 0, backMoney: 0, outNumber: 0, outMoney: 0, currentStock: 0, currentMoney: 0 };
        filterData = data.map(item => {
          //计算合计
          totalRow.lastMonthStock += item.lastMonthStock;
          totalRow.lastMonthMoney += item.lastMonthMoney;
          totalRow.buyNumber += item.buyNumber;
          totalRow.buyMoney += item.buyMoney;
          totalRow.backNumber += item.backNumber;
          totalRow.backMoney += item.backMoney;
          totalRow.outNumber += item.outNumber;
          totalRow.outMoney += item.outMoney;
          totalRow.currentStock += item.currentStock;
          totalRow.currentMoney += item.currentMoney;
          return [
            { v: item.name, s: { alignment: { horizontal: 'left', vertical: 'center' } } },
            { v: item.mode, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.price.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.lastMonthStock, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.lastMonthMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.buyNumber, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.buyMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.backNumber, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.backMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.outNumber, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.outMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.currentStock, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.currentMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } }
          ]
        });
        const header = [
          [
            { v: '物品名称', s: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } },
            { v: '单位', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '单价', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '上月结存', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            '',
            { v: '本月购入', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            '',
            { v: '本月退还', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            '',
            { v: '本月消耗', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            '',
            { v: '本月结存', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            ''
          ],
          [
            { v: '物品名称', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '单位', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '单价', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '数量', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '金额(元)', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '数量', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '金额(元)', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '数量', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '金额(元)', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '数量', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '金额', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '数量', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '金额', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } }
          ],
        ];
        const lastRow = [
          [{ v: '合计', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            '',
            '',
          { v: totalRow.lastMonthStock, s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.lastMonthMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.buyNumber, s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.buyMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.backNumber, s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.backMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.outNumber, s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.outMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.currentStock, s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.currentMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },]
        ];
        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } },
          { s: { r: 2, c: 0 }, e: { r: 1, c: 0 } },
          { s: { r: 2, c: 1 }, e: { r: 1, c: 1 } },
          { s: { r: 2, c: 2 }, e: { r: 1, c: 2 } },
          { s: { r: 1, c: 3 }, e: { r: 1, c: 4 } },
          { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
          { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },
          { s: { r: 1, c: 9 }, e: { r: 1, c: 10 } },
          { s: { r: 1, c: 11 }, e: { r: 1, c: 12 } },
          { s: { r: filterData.length + 3, c: 0 }, e: { r: filterData.length + 3, c: 2 } },
        ];
        const excelData = header.concat(filterData, lastRow);
        ExporttoExcel(excelData, name, merges);
      }
      //物品分类统计报表
      if (this.form.type == 2) {
        name = `${this.form.startDate} - ${this.form.endDate}物资分类报表`
        const totalRow = { lastMoney: 0, inMoney: 0, returnMoney: 0, receiveMoney: 0, borrowMoney: 0, scrapMoney: 0, currentMoney: 0 };
        filterData = data.map(item => {
          totalRow.lastMoney += item.lastMoney;
          totalRow.inMoney += item.inMoney;
          totalRow.returnMoney += item.returnMoney;
          totalRow.receiveMoney += item.receiveMoney;
          totalRow.borrowMoney += item.borrowMoney;
          totalRow.scrapMoney += item.scrapMoney;
          totalRow.currentMoney += item.currentMoney;
          return [
            { v: item.name, s: { alignment: { horizontal: 'left', vertical: 'center' } } },
            { v: item.lastMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.inMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.returnMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.receiveMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.borrowMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.scrapMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.currentMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
          ]
        });
        const header = [
          [
            { v: '物资类别', s: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } },
            { v: '上期余额', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '本月收入', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '本月退还', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '本月领用', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '本月借用', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '本月报废', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '本期余额', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          ]
        ];
        const lastRow = [[
          { v: '合计', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.lastMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.inMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.returnMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.receiveMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.borrowMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.scrapMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          { v: totalRow.currentMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
        ]]
        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }
        ];
        const excelData = header.concat(filterData, lastRow);
        ExporttoExcel(excelData, name, merges);
      }
      //部门物资领用报表
      if (this.form.type == 3) {
        name = `${this.form.startDate} - ${this.form.endDate}部门物资领用报表`;
        const totalRow = [];
        filterData = data.map(item => {
          let list = [
            { v: item.name, s: { alignment: { horizontal: 'left', vertical: 'center' } } },
          ]
          item.categoryVos.forEach((category, i) => {
            totalRow[i] = totalRow[i] + category.price || 0.00;
            list.push({ v: category.price.toFixed(2) || 0.00, s: { alignment: { horizontal: 'center', vertical: 'center' } } })
          });
          return list;
        });
        const header = [
          [
            { v: '部门名称', s: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } }
          ]
        ];
        const lastRow = [[
          { v: '合计', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } }
        ]]
        data[0]['categoryVos'].forEach((category, i) => {
          header[0].push({ v: category.name, s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } })
          lastRow[0].push({ v: totalRow[i].toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } })
        });
        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: Object.keys(filterData[0]).length } }
        ];
        ExporttoExcel(header.concat(filterData, lastRow), name, merges);
      }
      //采购/借用/归还/报废 物品明细报表
      if (this.form.type == 4 || this.form.type == 6 || this.form.type == 7 || this.form.type == 8) {
        name = `${this.form.startDate} - ${this.form.endDate}`
        if (this.form.type == 4) {
          name += "采购物品明细报表";
        }
        if (this.form.type == 6) {
          name += "借用物品明细报表";
        }
        if (this.form.type == 7) {
          name += "归还物品明细报表";
        }
        if (this.form.type == 8) {
          name += "报废物品明细报表";
        }
        let totalMoney = 0;
        filterData = data.map(item => {
          const itemMoney = (item.number * item.goodsPrice).toFixed(2);//小计
          totalMoney += parseFloat(itemMoney);
          return [
            { v: item.goodsName, s: { alignment: { horizontal: 'left', vertical: 'center' } } },
            { v: item.type, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.number + item.mode, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.goodsPrice.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.createTime, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.staffName, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: itemMoney, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            { v: item.remark, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
          ]
        });
        const header = [
          [
            { v: '物品名称', s: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } },
            { v: '登记类型', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '数量', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '单价', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '操作日期', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '操作员', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '小计', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '备注', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          ]
        ];
        const lastRow = [[
          { v: '合计', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          '',
          '',
          '',
          '',
          '',
          { v: totalMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          '',
        ]]
        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }
        ];
        const excelData = header.concat(filterData, lastRow);
        ExporttoExcel(excelData, name, merges);
      }
      //部门领用细报表
      if (this.form.type == 5) {
        name = `${this.form.startDate} - ${this.form.endDate}部门领用细报表`
        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }
        ];
        let startRow = 2;
        let filterData = [];
        data.map((item, k) => {
          let totalMoney = 0;
          let result = item.data.map((row, i) => {
            const rowMoney = (row.number * row.goodsPrice).toFixed(2);//小计
            totalMoney += parseFloat(rowMoney);
            return [
              { v: item.name, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: i + 1, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: row.goodsName, s: { alignment: { horizontal: 'left', vertical: 'center' } } },
              { v: row.type, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: row.number + row.mode, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: row.goodsPrice.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: row.createTime, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: row.staffName, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: row.auditName, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: rowMoney, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
              { v: row.remark, s: { alignment: { horizontal: 'center', vertical: 'center' } } },
            ]
          })
          result.push([
            '',
            { v: '合计', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            { v: totalMoney.toFixed(2), s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            '',
          ])
          //合并
          merges.push({ s: { r: startRow, c: 0 }, e: { r: startRow + item.data.length, c: 0 } })
          startRow += item.data.length + 1;
          filterData = filterData.concat(result)
        });
        const header = [
          [
            { v: '部门名称', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '序号', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '物品名称', s: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } },
            { v: '登记类型', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '数量', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '单价', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '操作日期', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '领用人', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '审批人', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '小计', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
            { v: '备注', s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true } } },
          ]
        ];
        const excelData = header.concat(filterData);
        ExporttoExcel(excelData, name, merges);
      }
    }
  },
  props: {
    categoryList: {
      type: Array,
      default() {
        return []
      }
    }
  }
};
</script>

<style>
.content img {
  max-width: 100% !important;
}

.countdown {
  width: 30px;
  height: 30px;
  border: 1px solid blue;
  border-radius: 50%;
  text-align: center;
}

.time {
  line-height: 30px;
}
</style>
